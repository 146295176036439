import React, { useState } from "react";
import {
  Row,
  Col,
  Button
} from "antd";
import _ from "lodash";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COLOR, FONT } from "../../Theme";
import TokenPackageOption from "./TokenPackageOption";
import {
  Link
} from "react-router-dom";
import NeedHelpCard from "../NeedHelpCard";
const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const default_banner_img = require("../../assets/default_banner.png");

const TokenMobileView = ({
  tokenPackageArr,
  clickNext,
  buttonLoading
}) => {
  const { t, i18n } = useTranslation();
  const { companySiteMeta, client } = useSelector((state) => state.app);
  const { MEDIA_URL } = client.config;
  const { company_key } = useParams();
  const [selectedTokenPackage, setSelectedTokenPackage] = useState({});

  let tokenBannerPath = companySiteMeta.token_banner ? `${MEDIA_URL}/${companySiteMeta.token_banner}` : default_banner_img;

  const handleClickTokenPackage = (tokenPackage) => {
    setSelectedTokenPackage(tokenPackage);
  };

  return (
    <div style={{
      ...styles.outerContainer
    }}
    >
      <img
        src={tokenBannerPath}
        alt="token banner"
        style={{
          ...styles.tokenBanner
        }}
      />
      <div style={{
        ...styles.section,
        padding: "24px 16px",
        textAlign: "center"
      }}
      >
        <div style={{
          ...FONT.h4,
          color: COLOR.primary.primary500,
          marginBottom: 12
        }}
        >
          {companySiteMeta[`token_name_${i18n.language}`]}
        </div>
        <div>
          {htmlToReactParser.parse(companySiteMeta[`token_description_${i18n.language}`])}
        </div>
      </div>
      <div style={{
        ...styles.section
      }}
      >
        <div style={{
          ...FONT.titleTextBold,
          color: COLOR.primary.primary500,
          marginBottom: 8
        }}
        >
          {t("token")}
        </div>
        <div style={{
          ...FONT.titleTextRegular,
          color: COLOR.text.captionText,
        }}
        >
          {t("please_select_token_plan")}
        </div>
        <div style={{
          marginTop: 16,
          marginBottom: 24
        }}
        >
          <Row gutter={[16, 16]}>
            {
              _.map(tokenPackageArr, (tokenPackage) => {
                return (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    key={tokenPackage.token_package_id}
                  >
                    <TokenPackageOption
                      tokenPackage={tokenPackage}
                      isSelected={
                        selectedTokenPackage.token_package_id === tokenPackage.token_package_id
                      }
                      onClick={() => handleClickTokenPackage(tokenPackage)}
                    />
                  </Col>
                );
              })
            }
          </Row>
        </div>
        <Button
          style={{
            ...styles.nextButton,
            ...FONT.titleTextRegular
          }}
          loading={buttonLoading}
          onClick={() => clickNext(selectedTokenPackage)}
        >
          {t("next")}
        </Button>
        <Link
          style={{
            color: COLOR.text.captionText,
            textDecorationLine: "underline"
          }}
          to={`/${company_key}`}
        >
          {t("recurring_payments_details_link")}
        </Link>
      </div>
      <NeedHelpCard
        title={t("need_help_title")}
      />
    </div>
  );
};

const styles = {
  section: {
    backgroundColor: COLOR.function.background,
    padding: "16px",
    marginBottom: 24,
  },
  outerContainer: {
    width: "calc(100% + 20px)",
    marginLeft: -10
  },
  tokenBanner: {
    objectFit: "contain",
    marginBottom: "10px",
    width: "100%"
  },
  nextButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    width: "100%",
    height: 48,
    borderRadius: 8,
    backgroundColor: COLOR.primary.primary500,
    color: "white",
    marginBottom: 24
  }
};

export default TokenMobileView;
