import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import { COLOR, FONT } from "../../Theme";
import LoadingIcon from "../LoadingIcon";
import { setCompanyInfo, setQRCodeVisible, setUser } from "../../redux/actions/common";
import { ReactComponent as PremiumLogo } from "../../assets/premium_logo.svg";
import * as Main from "../../core/Main";

const ProfileDesktopView = ({
  userInfo,
  renderTokenExpiryDate
}) => {
  const { t } = useTranslation();
  const { company, user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { company_key } = useParams();
  const history = useHistory();
  const router = useHistory();
  const {
    nickname,
    token_own,
    token_expiry_time,
    tokenDetailList
  } = userInfo;

  const menuList = [
    {
      key: "access_qr_code",
      name: "access_qr_code",
      path: ""
    },
    {
      key: "order_record",
      name: "order_record",
      path: "/order"
    },
    // {
    //   key: "token_receipt",
    //   name: "token_receipt",
    //   path: ""
    // },
    // {
    //   key: "manage_booking_info",
    //   name: "manage_booking_info",
    //   path: ""
    // },
    // {
    //   key: "manage_membership",
    //   name: "manage_membership",
    //   path: ""
    // }
  ];

  return (
    <Row style={{ padding: "2% 4%" }}>
      {/* user container */}
      <Col
        style={styles.container}
        span={24}
      >
        <span
          style={{ ...FONT.h5 }}
        >
          {nickname}
        </span>
        <Row
          gutter={[10, 0]}
        >
          <Col
            style={{ ...FONT.titleTextRegular }}
          >
            {t("total_token")}
            :
            {" "}
          </Col>
          <Col
            style={{
              ...FONT.titleTextBold,
              color: COLOR.textPrimary
            }}
          >
            {token_own?.toLocaleString()}
          </Col>
        </Row>
        <Row
          style={{ marginBottom: 30 }}
          gutter={[10, 0]}
        >
          <Col
            style={{
              ...FONT.titleTextBold,
              color: COLOR.captionText
            }}
          >
            {t("token_expire")}
            :
            {" "}
          </Col>
          <Col
            style={{
              ...FONT.bodyText2Regular,
              color: COLOR.captionText
            }}
          >
            {renderTokenExpiryDate(tokenDetailList, token_expiry_time)}
          </Col>
        </Row>
        {
          !user?.is_guest
          && (
            <>
              <div
                style={{
                  ...FONT.titleTextRegular,
                  color: COLOR.captionText,
                  cursor: "pointer",
                  padding: "16px 0",
                }}
                onClick={() => history.push(`/${company_key}/profile/edit`)}
              >
                {t("edit_personal_info")}
              </div>

              <div
                style={{
                  ...FONT.titleTextRegular,
                  color: COLOR.captionText,
                  cursor: "pointer",
                  padding: "16px 0",
                }}
                onClick={() => history.push(`/${company_key}/profile/edit_password`)}
              >
                {t("edit_password")}
              </div>
            </>
          )
        }
      </Col>

      {/* membership related container */}
      {/* <Col
        style={styles.container}
        span={24}
      >
        <Row gutter={[10, 15]}>
          <Col>
            <Col
              style={{
                ...FONT.bodyText1Bold,
                color: COLOR.text.blackText
              }}
            >
              {t("upgrade_to_premium")}
            </Col>
            <Col
              style={{
                ...FONT.bodyText2Regular,
                color: COLOR.captionText
              }}
            >
              Lorem ipsum dolor sit amet, consectetur
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "end"
            }}
          >
            <PremiumLogo />
          </Col>
          <Col
            span={24}
          >
            <Button
              style={{
                ...FONT.buttonRegular,
                backgroundColor: COLOR.primary.primary500,
                color: COLOR.text.whiteText,
                width: "100%",
                height: "48px",
                borderRadius: "8px"
              }}
              onClick={() => history.push(`/${company_key}/membership/list`)}
            >
              {t("get_it")}
            </Button>
          </Col>
        </Row>
      </Col> */}

      {/* menu container */}
      <Col
        style={styles.container}
        span={24}
      >
        {_.map(menuList, (eachMenu) => {
          return (
            <Col
              key={eachMenu.key}
              style={{
                ...FONT.titleTextRegular,
                color: COLOR.text.captionText,
                padding: "16px 0",
                cursor: "pointer"
              }}
              onClick={() => {
                if (eachMenu.key === "access_qr_code") {
                  return dispatch(setQRCodeVisible(true));
                }
                history.push(`/${company_key}${eachMenu.path}`);
              }}
            >
              {t(eachMenu.name)}
            </Col>
          );
        })}
      </Col>

      <Col
        style={{
          ...styles.container,
          ...FONT.buttonRegular,
          color: COLOR.text.primaryText,
          textAlign: "center",
          cursor: "pointer"
        }}
        span={24}
        onClick={() => {
          Main.logout(router, company.company_key);
        }}
      >
        {t("logout")}
      </Col>
    </Row>
  );
};

const styles = {
  container: {
    backgroundColor: COLOR.function.background,
    padding: "16px",
    marginBottom: 30,
  }
};

export default ProfileDesktopView;
