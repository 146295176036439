import React, { useState } from "react";
import {
  Grid, Modal, Row, Col, Button, notification
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import { setShoppingCartAmount } from "../redux/actions/common";
import * as Service from "../core/Service";
import { COLOR, FONT, BREAKPOINT } from "../Theme";
import ShoppingCartItems from "./ShoppingCartItem";

const CancelOrderModal = ({
  cancelTarget = {},
  visible,
  setVisible,
  type = "",
  mode = "",
  orderStatus = "",
  setNeedUpdate = () => { }
}) => {
  const [modalLoading, setModalLoading] = useState(false);
  const { company } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.md;
  const headingTextStyle = isMobile ? { ...FONT.h5 } : { ...FONT.h3 };
  const subHeadingTextStyle = isMobile ? { ...FONT.text } : { ...FONT.textMedium };

  const onCancelShoppingCartItem = async () => {
    try {
      setModalLoading(true);
      // server action
      if (_.isUndefined(cancelTarget?.shopping_cart_item_id)) {
        return;
      }
      const resp = await Service.call("post", "/api/shopping_cart/item/remove", { shopping_cart_item_id: cancelTarget?.shopping_cart_item_id });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      const { bookingInfoList } = resp;
      dispatch(setShoppingCartAmount(bookingInfoList.length));
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
      setVisible(false);
    }
  };

  const onCancelOrderItem = async () => {
    try {
      setModalLoading(true);
      // server action
      const {
        order_id,
        order_item_id,
        item_id,
        item_type,
      } = cancelTarget;
      const targetObj = {
        order_id,
        order_item_id,
        item_id,
        item_type
      };
      let method = "post";
      let url = "/api/order/item/remove";
      if (_.includes([
        "payment_confirmed",
        "partial_payment_refund",
        "partial_payment_refund_token",
        "partial_payment_refund_offline"
      ], orderStatus)) {
        url = "/api/order/refund/item";
        method = "patch";
      }
      const resp = await Service.call(method, url, targetObj);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      // fail case
      setNeedUpdate(true);
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
      setVisible(false);
    }
  };

  const onCancelOrderInfo = async () => {
    try {
      setModalLoading(true);
      const { order_id } = cancelTarget;
      const targetObj = { order_id };
      // console.log(cancelTarget)
      const resp = await Service.call(
        "post",
        "/api/order/clear",
        targetObj
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      Modal.success({
        content: t("cancel_booking_success"),
        okButtonProps: {
          style: {
            borderColor: COLOR.brandSecondary,
            backgroundColor: COLOR.brandSecondary,
            color: COLOR.whiteSecondary
          }
        },
        onOk: () => {
          history.replace(`/${company.company_key}`);
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
      setVisible(false);
    }
  };

  let cancelFunction = () => { };
  switch (type) {
    case "drawer":
      cancelFunction = onCancelShoppingCartItem;
      break;
    case "order":
    case "payment":
      cancelFunction = mode === "item" ? onCancelOrderItem : onCancelOrderInfo;
      break;
    default:
      break;
  }

  return (
    <Modal
      className="cancel-order-modal"
      destroyOnClose
      visible={visible}
      onCancel={() => {
        if (!modalLoading) {
          setVisible(false);
        }
      }}
      bodyStyle={{ padding: 16 }}
      closable={false}
      centered
      footer={null}
    >
      <div>
        <Row
          justify="center"
          style={{
            ...FONT.titleTextBold,
            color: COLOR.text.blackText,
          }}
        >
          {t("cancel_booking")}
        </Row>
        <Row
          justify="center"
          style={{
            color: COLOR.text.captionText,
            // ...FONT.titleTextRegular,
            fontSize: 16,
            margin: "16px 0",
            textAlign: "center",
          }}
        >
          {mode === "item"
            ? _.includes([
              "payment_confirmed",
              "partial_payment_refund",
              "partial_payment_refund_token",
              "partial_payment_refund_offline"
            ], orderStatus)
              ? t("are_you_sure_cancel_this_item")
              : t("are_you_sure_remove_this_item")
            : t("are_you_sure_cancel_booking")}
        </Row>
        {/* {type === 'order' && _.includes(['payment_confirmed', 'partial_payment_refund'], orderStatus) && (
        <Row justify="center" style={subHeadingTextStyle}>
          <span style={{ color: COLOR.warningRed, textAlign: 'center' }}>請注意:訂單費用不會退回，只會退回代幣。</span>
        </Row>
        )}
        {mode === 'item' && (
          <ShoppingCartItems
            borderless
            item={cancelTarget}
          />
        )} */}
      </div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Button
            block
            size="large"
            disabled={modalLoading}
            className="ghost-btn"
            style={{
              backgroundColor: COLOR.function.white,
              color: COLOR.gray.gray500,
            }}
            onClick={() => setVisible(false)}
          >
            {t("cancel")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            block
            size="large"
            type="primary"
            loading={modalLoading}
            className="primary-btn"
            onClick={cancelFunction}
          >
            {t("confirm")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CancelOrderModal;
