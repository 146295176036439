import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox, Col, Form, Input, Modal, notification, Row, Select
} from "antd";
import _ from "lodash";
import Button from "../Button";
import * as Service from "../../core/Service";
import { openNotificationWithIcon } from "./ErrorMessage";
import userIcon from "../../assets/user.svg";
import lockIcon from "../../assets/lock.svg";
import envelopeIcon from "../../assets/envelope.svg";
import mobileIcon from "../../assets/mobile-alt.svg";
import penIcon from "../../assets/pen.svg";
import { COLOR, FONT } from "../../Theme";
import SendOTPButton from "../SendOTPButton";
import {
  setAppModal, setAuth, setOrderInfo, setShoppingCartAmount, setUser, setUserSession
} from "../../redux/actions/common";
import * as Main from "../../core/Main";

const LOGIN_METHOD = {
  username: 1,
  email: 2,
  mobile: 3
};

const areaCodeList = [
  { label: "+852", value: "+852" },
  { label: "+86", value: "+86" },
];

const LoginForm = (props) => {
  const [loginForm] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const {
    color_primary,
    color_secondary,
    login_method,
    login_otp,
    login_password,
    allow_register
  } = app.company;

  const {
    onSuccess
  } = props;

  useEffect(() => {
    loginForm.resetFields();
    loginForm.setFieldsValue({
      mobilePrefix: "+852"
    });
  }, []);

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      formData.mobile = `${formData.mobilePrefix}-${formData.mobile}`;
      let resp = await Service.call("post", "/api/user/login", formData);
      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), resp.errorMessage);
      }
      const {
        userSession,
        userInfo,
        orderList,
        shoppingCartItemArr
      } = resp;
      Main.setUserSession(userSession);
      dispatch(setAuth(true));
      dispatch(setOrderInfo(orderList));
      dispatch(setUser(userInfo));
      dispatch(setShoppingCartAmount(
        _.filter(shoppingCartItemArr, { parent_shopping_cart_item_id: 0 }).length
      ));
      dispatch(setUserSession(userSession));
      dispatch(setAppModal({
        appModalVisible: false,
        type: null
      }));
      onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderLoginMethod = () => {
    if (login_method === LOGIN_METHOD.email) {
      return (
        <Form.Item
          name="email"
          rules={[{
            required: true,
            message: t("require_email"),
            type: "email"
          }]}
        >
          <Input
            placeholder={t("email")}
            prefix={<img style={{ margin: "0px 5px", }} src={envelopeIcon} alt="prefix_icon" />}
            style={styles.inputContainer}
            allowClear
            bordered={false}
          />
        </Form.Item>
      );
    }
    if (login_method === LOGIN_METHOD.mobile) {
      return (
        <Row gutter={[15, 0]} style={{ display: "flex", alignItems: "center" }}>
          <Col xs={6} sm={6} md={4}>
            <Form.Item
              name="mobilePrefix"
            >
              <Select
                showArrow={false}
                options={areaCodeList}
              />
            </Form.Item>
          </Col>

          <Col xs={18} sm={18} md={20}>
            <Form.Item
              name="mobile"
              rules={[{
                required: true,
                message: t("require_mobile"),
              }]}
            >
              <Input
                placeholder={t("mobile")}
                prefix={<img style={{ margin: "0px 5px", }} src={mobileIcon} alt="prefix_icon" />}
                style={styles.inputContainer}
                allowClear
                bordered={false}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return (
      <Form.Item
        name="username"
        rules={[{
          required: true,
          message: t("require_username"),
        }]}
      >
        <Input
          placeholder={t("username")}
          prefix={<img style={{ margin: "0px 5px", }} src={userIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
    );
  };

  const createGuest = async () => {
    try {
      let url = "/api/user/guest";
      let resp = await Service.call("POST", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { guestRc, userSession } = resp.data;
      Main.setUserSession(userSession);
      dispatch(setAuth(true));
      dispatch(setUser(guestRc));
      dispatch(setUserSession(userSession));
      dispatch(setAppModal({
        appModalVisible: false,
        type: null
      }));

      notification.success({ message: "success" });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Form
      className="loginForm"
      form={loginForm}
      name="UserForm"
      layout="vertical"
      onFinish={onFinish}
    >

      <p style={{ ...FONT.h3, margin: "30px 0" }}>
        {t("login_to_continue")}
      </p>

      {renderLoginMethod()}
      {Boolean(login_otp) && (
        <Form.Item
          name="otp_code"
          rules={[{
            required: true,
            message: t("require_otp"),
          }]}
        >
          <Input
            placeholder={t("enter_otp")}
            prefix={<img style={{ margin: "0px 5px", }} src={penIcon} alt="prefix_icon" />}
            suffix={<SendOTPButton />}
            style={styles.inputContainer}
            allowClear
            bordered={false}
          />
        </Form.Item>
      )}
      {Boolean(login_password) && (
        <Form.Item
          name="password"
          rules={[{
            required: true,
            message: t("require_password"),
          }]}
        >
          <Input.Password
            placeholder={t("password")}
            prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
            style={styles.inputContainer}
            allowClear
            bordered={false}
          />
        </Form.Item>
      )}
      <Row justify="space-between" style={{ marginBottom: "20px" }}>
        <Col>
          <Checkbox>
            <p
              style={{
                ...FONT.bodyText2Regular,
                color: COLOR.captionText
              }}
            >
              {t("remember_login")}
            </p>
          </Checkbox>
        </Col>
        <Col onClick={() => {
          dispatch(setAppModal({
            appModalVisible: true,
            type: Main.FORM_TYPE.FORGET_PASSWORD
          }));
        }}
        >
          <a style={{ color: color_primary }}>
            {t("forget_your_password?")}
          </a>
        </Col>
      </Row>
      <Form.Item>
        <Button
          style={{
            width: "100%",
            borderRadius: "8px"
          }}
          theme_color={color_primary}
          size="large"
          htmlType="submit"
          loading={loading}
        >
          {t("login")}
        </Button>
      </Form.Item>
      {Boolean(allow_register) && (
        <Row justify="center" gutter={[15, 0]}>
          <Col>
            <p
              style={{
                color: "#828282",
                fontSize: "14px"
              }}
            >
              {t("not_register_yet")}
            </p>
          </Col>
          <Col onClick={() => {
            dispatch(setAppModal({
              appModalVisible: true,
              type: Main.FORM_TYPE.REGISTER
            }));
          }}
          >
            <a style={{ color: color_primary }}>
              {t("create_account")}
            </a>
          </Col>
          {/* <Col onClick={createGuest}
          >
            <a style={{ color: color_primary }}>
              {t("create_guest")}
            </a>
          </Col> */}
        </Row>
      )}
    </Form>
  );
};

export default LoginForm;

const styles = {
  inputContainer: {
    borderRadius: "8px",
    backgroundColor: "#FBF9F9",
    height: "45px",
  }
};
