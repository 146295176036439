import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import dayjs from "dayjs";
import * as Service from "../core/Service"
import { setCompanyInfo, setLoading, setUser } from "../redux/actions/common";
import AppLayout from "../components/AppLayout";
import ProfileMobileView from "../components/profile/ProfileMobileView";
import ProfileDesktopView from "../components/profile/ProfileDesktopView";
import { BREAKPOINT, COLOR, FONT } from "../Theme";
import { useTranslation } from "react-i18next";

const ProfilePageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      let url = "/api/user/profile";
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { userInfo: userData } = resp;
      let tokenOwnFromDetail = 0;
      let { tokenDetailList } = resp;
      if (!_.isEmpty(tokenDetailList)) {
        tokenDetailList = _.filter(tokenDetailList, (item) => item.token_remain > 0);
        tokenDetailList = _.orderBy(tokenDetailList, ["token_expiry_time"], ["asc"]);
        _.map(tokenDetailList, (eachRc) => {
          if (eachRc.token_expiry_time > dayjs().unix()) {
            tokenOwnFromDetail += eachRc.token_remain;
          }
        });
      }
      // setOrderList(_.orderBy(orderArr, ["ctime"], ["desc"]));
      dispatch(setUser({
        ...userData,
        token_own: tokenOwnFromDetail,
        tokenDetailList
      }));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const renderTokenExpiryDate = (tokenList, token_expiry_time) => {
    if (_.isEmpty(tokenList)) {
      return (
        <div>
          {token_expiry_time ? dayjs.unix(token_expiry_time).format("DD-MM-YYYY") : "-"}
        </div>
      );
    }
    const firstExpiryRc = _.first(tokenList);
    const lastExpiryRc = _.last(tokenList);
    return (
      <Row>
        <Col span={24}>
          <span style={{
            ...FONT.titleTextBold,
            color: COLOR.textPrimary
          }}
          >
            {firstExpiryRc.token_remain}
          </span>
          {" "}
          {t("token_will_be_expired_at", {
            date: dayjs.unix(firstExpiryRc.token_expiry_time).format("DD-MM-YYYY")
          })}
        </Col>
        {firstExpiryRc !== lastExpiryRc && lastExpiryRc && (
          <Col span={24}>
            {t("other_tokens_will_be_expired", {
              date: dayjs.unix(lastExpiryRc.token_expiry_time).format("DD-MM-YYYY")
            })}
          </Col>
        )}
      </Row>
    );
  };

  return (
    <AppLayout>
      {isMobile ? (
        <ProfileMobileView
          userInfo={user}
          renderTokenExpiryDate={renderTokenExpiryDate}
        />
      ) : (
        <ProfileDesktopView
          userInfo={user}
          renderTokenExpiryDate={renderTokenExpiryDate}
        />
      )}
    </AppLayout>
  );
};

export default ProfilePageContainer;
