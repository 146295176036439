import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COLOR, FONT } from "../../Theme";

const default_token_img = require("../../assets/default_token_img.png");

const TokenPackageOption = ({
  tokenPackage,
  isSelected,
  ...others
}) => {
  const {t, i18n} = useTranslation();
  const { companySiteMeta, client } = useSelector((state) => state.app);
  const { MEDIA_URL } = client.config;

  let imagePath = companySiteMeta?.token_img ? `${MEDIA_URL}/${companySiteMeta.token_img}` : default_token_img;

  return (
    <div
      style={{
        ...FONT.titleTextBold,
        padding: 16,
        borderRadius: 8,
        color: COLOR.primary.primary500,
        backgroundColor: isSelected ? COLOR.primary.primary50 : "white",
        cursor: "pointer"
      }}
      {...others}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 12
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 8,
          }}
        >
          <img
            src={imagePath}
            alt="token pic"
            style={{
              maxWidth: 30,
              maxHeight: 30
            }}
          />
        </div>
        {companySiteMeta[`token_name_${i18n.language}`]}
      </div>
      <div
        style={{
          color: COLOR.function.black,
          marginBottom: 4
        }}
      >
        <span style={{
          marginRight: 4
        }}
        >
          {tokenPackage?.token_value}
        </span>
        <span>
          {t("tokens")}
        </span>
      </div>
      <div
        style={{
          color: COLOR.text.captionText
        }}
      >
        <span>
          HKD$
        </span>
        <span>
          {tokenPackage?.package_price}
        </span>
      </div>
    </div>
  );
};

export default TokenPackageOption;
