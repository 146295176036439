import {
  Checkbox, Col, Collapse, Divider, InputNumber, Row, Space, Grid, Button
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import _ from "lodash";
import dayjs from "dayjs";
import { useWindowWidth } from "@react-hook/window-size";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { BREAKPOINT, COLOR, FONT } from "../../Theme";
import CancelOrderModal from "../CancelOrderModal";
import * as Main from "../../core/Main";

const { Panel } = Collapse;

const ListItem = ({ title, value, style = {} }) => {
  const styles = {
    title: { color: COLOR.captionText },
    value: { textAlign: "right" },
  };

  return (
    <Col span={24}>
      <Row justify="space-between" align="middle" style={{ ...style }}>
        <Col style={{ ...styles.title }}>
          {title}
          :
        </Col>
        <Col style={{ ...styles.value }}>{value}</Col>
      </Row>
    </Col>
  );
};

const OrderSummarySection = ({
  step,
  orderDetail,
  orderInfo,
  paymentType,
  useToken,
  setUseToken,
  token = 0,
  setToken,
  total,
  setNeedUpdate
}) => {
  const { t } = useTranslation();
  const { user, company } = useSelector((state) => state.app);
  const { token_ratio } = company;

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  const styles = {
    sectionTitle: {
      fontWeight: "bold",
      fontSize: 16,
      color: COLOR.brandPrimary,
    },
    highlightValue: {
      color: COLOR.brandPrimary,
      fontWeight: "bold"
    }
  };

  const item_types = _.map(orderDetail, (item) => item.item_type);
  const isTokenOrder = _.includes(item_types, 5);

  const {
    original_price,
    currency,
    token_earned,
    status,
    order_type,
    order_discount,
    discount
  } = orderInfo;

  const sumOfItems = _.sumBy(orderDetail, "price");
  return (
    <>
      {/* Order summary section */}
      <div
        className="order-summary-card"
        style={{ borderRadius: !isMobile && 8 }}
      >
        <Collapse
          className="order-summary-collapse"
          style={{ borderRadius: !isMobile && 8 }}
          bordered={false}
          expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
          expandIconPosition="right"
          defaultActiveKey="default"
        >
          <Panel
            key="default"
            header={
              <div style={{ ...styles.sectionTitle }}>{t("order_summary")}</div>
            }
          >
            <OrderSummary
              orderStatus={status}
              orderDetail={orderDetail}
              token={token}
              setToken={setToken}
              setNeedUpdate={setNeedUpdate}
            />
          </Panel>
        </Collapse>
        <div style={{ padding: "0 16px 16px" }}>
          <Row gutter={[0, 12]}>
            <ListItem
              title={`${t("items")}(${orderDetail?.length})`}
              value={`$${original_price}`}
            />
            {_.includes([1, 2], order_type) && (
              <ListItem
                title={`${t("rentals_items")}(${_.sumBy(
                  orderDetail,
                  "childItems.length"
                )})`}
                value={`$${_.sumBy(orderDetail, "product_price")}`}
              />
            )}
            <ListItem
              title={t("discount")}
              value={`-$${order_discount + discount || 0}`}
            />
            <ListItem
              title={t("point_discount")}
              value={`-$${(token * token_ratio) || 0}`}
            />
          </Row>
          <Divider className="small-divider" />

          <Row gutter={[0, 12]}>
            {/* <ListItem
              title={t("subtotal")}
              value={`$${sumOfItems}`}
            /> */}
            <ListItem
              title={t("payment_total")}
              value={
                <div style={{ ...styles.highlightValue }}>
                  {`${_.toUpper(currency)}$${(sumOfItems - order_discount - discount - token * token_ratio)}`}
                </div>
              }
            />
          </Row>

          {/* <Divider className="small-divider" />
          <div style={{ textAlign: "right" }}>
            <Space size={4}>
              {t("get")}
              <span style={{ ...styles.highlightValue }}>{token_earned}</span>
              {t("get_point")}
            </Space>
          </div> */}
        </div>
      </div>

      {/* Discount section */}
      {!isTokenOrder && step === 1 && paymentType === "pre-payment" && (
        <div className="section-card" style={{ marginTop: 24, borderRadius: !isMobile && 8 }}>
          <Row>{t("enter_promo_code_desc")}</Row>
          <Row gutter={[0, 10]} style={{ marginTop: 28 }}>
            <Col span={12}>
              <Checkbox
                disabled={!user.token_own}
                checked={useToken}
                onChange={(e) => {
                  setUseToken(e.target.checked);
                  setToken(null);
                }}
              >
                {t("use_my_token")}
              </Checkbox>
            </Col>
            <Col span={12}>
              <InputNumber
                keyboard
                disabled={!useToken}
                min={0}
                max={user.token_own}
                style={{ color: COLOR.brandPrimary }}
                value={token}
                onBlur={(e) => {
                  if (!user.token_own) {
                    return setToken(0);
                  }
                  let value = _.toInteger(e.target.value);
                  if (value > user.token_own) {
                    value = user.token_own;
                  }
                  if (_.isInteger(value)) {
                    const tokenPrice = value * token_ratio;
                    if (tokenPrice > total) {
                      value = Math.floor(total / token_ratio);
                    }
                    return setToken(value);
                  }
                  return setToken(0);
                }}
              />
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space size={4}>
                {t("you_have")}
                <span style={{ color: COLOR.brandPrimary }}>
                  {user.token_own ? user.token_own : 0}
                </span>
                {t("token")}
              </Space>
            </Col>
          </Row>
          {/* <Row gutter={12}>
            <Col flex="1">
              <Input
                placeholder="Enter your promo code"
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                disabled={!promoCode}
                type="primary"
                style={{ borderRadius: 8, padding: "8px 16px", height: "100%" }}
              >
                Apply
              </Button>
            </Col>
          </Row> */}
        </div>
      )}
    </>
  );
};

export default OrderSummarySection;

const OrderSummary = ({
  orderDetail,
  setNeedUpdate,
  orderStatus
}) => {
  const { t } = useTranslation();
  const [cancelTarget, setCancelTarget] = useState({});
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  return (
    <>
      {_.map(orderDetail, (order) => {
        const {
          category_name,
          booking_item_name,
          start_time,
          end_time,
          childItems,
          order_item_id,
          quantity,
          order_id,
          item_id,
          item_type,
          token_amount
        } = order;
        const rentalItems = () => {
          const onStockItems = _.filter(
            childItems,
            (item) => item.quantity > 0
          );
          if (_.isEmpty(onStockItems)) return "";
          const rentalComponent = (
            <Row gutter={8}>
              {_.map(onStockItems, (item) => {
                const {
                  item_name,
                  quantity,
                  order_item_id
                } = item;
                return (
                  <Col key={order_item_id} span={24}>
                    {item_name}
                    {" "}
                    x
                    {" "}
                    {quantity}
                  </Col>
                );
              })}
            </Row>
          );
          return <ListItem title={t("rentals")} value={rentalComponent} />;
        };

        const displayOrderSummaryItems = (item_type) => {
          switch (Main.ITEM_TYPE[item_type]) {
            case "booking":
            case "product":
              return (
                <BookingItem
                  booking_item_name={booking_item_name}
                  start_time={start_time}
                  end_time={end_time}
                  rentalItems={rentalItems}
                  quantity={quantity}
                  category_name={category_name}
                />
              );
            case "event":
              return (
                <EventItem
                  booking_item_name={booking_item_name}
                  start_time={start_time}
                  end_time={end_time}
                  quantity={quantity}
                />
              );
            case "membership":
              return (
                <MembershipItem
                  category_name={category_name}
                  token_amount={token_amount}
                />
              );
            case "token":
              return (
                <TokenItem
                  category_name={category_name}
                  token_amount={token_amount}
                />
              );
            default:
              return (
                <BookingItem
                  booking_item_name={booking_item_name}
                  start_time={start_time}
                  end_time={end_time}
                  rentalItems={rentalItems}
                  quantity={quantity}
                  category_name={category_name}
                />
              );
          }
        };

        return (
          <Row key={order_item_id} gutter={[0, 8]}>
            {displayOrderSummaryItems(item_type)}
            <Col
              span={24}
              style={{
                ...FONT.buttonRegular,
                textAlign: "right",
              }}
            >
              <Button
                type="ghost"
                style={{
                  border: "none",
                  color: "#9c1d14",
                  // backgroundColor: "white",
                  padding: 0
                }}
                onClick={() => {
                  setCancelTarget({
                    order_item_id,
                    order_id,
                    item_id,
                    item_type
                  });
                  setCancelModalVisible(true);
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {t("remove")}
                </span>
              </Button>
            </Col>
            <CancelOrderModal
              orderStatus={orderStatus}
              cancelTarget={cancelTarget}
              type="order"
              mode="item"
              visible={cancelModalVisible}
              setVisible={setCancelModalVisible}
              setNeedUpdate={() => {
                setNeedUpdate(true);
                setCancelTarget({});
              }}
            />
            <Divider style={{ margin: "8px 0 " }} />
          </Row>
        );
      })}
    </>
  );
};

const BookingItem = ({
  booking_item_name,
  start_time,
  end_time,
  rentalItems,
  quantity,
  category_name,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24}>{category_name}</Col>
      <ListItem title={t("item")} value={booking_item_name} />
      {rentalItems()}
      <ListItem
        title={t("date")}
        value={dayjs.unix(start_time).format("YYYY-MM-DD")}
      />
      <ListItem
        title={t("time")}
        value={`${dayjs.unix(start_time).format("HH:mm")} - ${dayjs
          .unix(end_time)
          .format("HH:mm")}`}
      />
      <ListItem title={t("capacity")} value={quantity || 1} />
    </>
  );
};

const EventItem = ({ booking_item_name, start_time, end_time, quantity }) => {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24}>{booking_item_name}</Col>
      <ListItem
        title={t("date")}
        value={`${dayjs.unix(start_time).format("YYYY-MM-DD")} to ${dayjs
          .unix(end_time)
          .format("YYYY-MM-DD")}`}
      />
      <ListItem title={t("quantity")} value={quantity} />
    </>
  );
};

const MembershipItem = ({ category_name, token_amount }) => {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24}>{category_name}</Col>
      <ListItem title={t("token_amount")} value={token_amount} />
    </>
  );
};

const TokenItem = ({ category_name, token_amount }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={24}>{category_name}</Col>
      <ListItem title={t("token_amount")} value={token_amount} />
    </>
  );
};
