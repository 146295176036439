import React from 'react';
import {
  Row,
} from "antd";
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import CardItem from "./CardItem";

const MembershipOrderDetail = ({ data }) => {
  const { t } = useTranslation();
  const {
    item_name,
    final_price,
    start_time,
    end_time
  } = data;

  return (
    <div>
      <Row gutter={[0, 12]}>
        <CardItem title={t("membership_type")} value={t(item_name)} />
        <CardItem title={t("price")} value={`$${final_price}`} />
        <CardItem
          title={t("start_date")}
          value={dayjs.unix(start_time).format("DD-MM-YYYY HH:mm:ss")}
        />
        <CardItem
          title={t("expiry_date")}
          value={dayjs.unix(end_time).format("DD-MM-YYYY HH:mm:ss")}
        />
      </Row>
    </div>
  );
};


export default MembershipOrderDetail;
